import getSymbolFromCurrency from 'currency-symbol-map';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { Image, Pressable, StyleSheet, Text, View } from 'react-native';
import { useDispatch } from 'react-redux';
import { palettes } from '../../../config';
import { useIsSpecificViewportWidth } from '../../../hooks/useIsSpecificViewportWidth';
import { setEventProduct } from '../../../redux/eventProductDetail';
import { transformToPopulated } from '../../../utils/productCategories';
import { twoDp } from '../../../utils/twoDP';
import ModalComponent from '../../common/modal/ModalComponent';
import ProductView from '../../common/templates/ProductView';
import Animated, {
	useAnimatedStyle,
	useSharedValue,
	withTiming,
} from 'react-native-reanimated';
import { useAppSelector } from '../../../redux/store';
import { Timestamp, addDoc, collection } from 'firebase/firestore';
import { db } from '../../../services/firebase';
import Button from '../../common/button/Button';
import { useAddToCart } from '../../../hooks/useAddToCart';
import { shortenName } from '../../../utils/manipulateString';
import { useTranslation } from 'react-i18next';
import { countryAndCurrency } from '../../../utils/countryNamesWithCodes';

export type EventProductCardProps = {
	shortDesc: string;
	productName: string;
	isHighlightedProduct?: boolean;
	price: number;
	currencyCountry?: string;
	imageLink: string;
	product?: TGetAllProductsData;
	variant?: Variant;
	eventDetails?: TSingleLiveEventData;
};

const EventProductCard = ({
	shortDesc,
	productName,
	isHighlightedProduct,
	price,
	imageLink: uri,
	product,
	variant,
	eventDetails,
}: EventProductCardProps) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const { isLessThanDesktopBase } = useIsSpecificViewportWidth();
	const { loggedInUserDetails, isUserLoggedIn } = useAppSelector(
		state => state.auth
	);
	const [selectedVariant, setSelectedVariant] = useState<PopulatedVariant>();
	const eventProduct = useAppSelector(state => state.eventProduct);
	const [modalState, setModalState] = useState(false);

	const { visitorCartStore } = useAppSelector(state => state.user);
	const cart = useAppSelector(state => state.user.cart);
	const usersCart = isUserLoggedIn ? cart : visitorCartStore || [];

	const { mutateCart, createLoading, isLoadingVisitorAddToCart } =
		useAddToCart({
			selectedVariant: eventProduct?.product,
			event: eventDetails,
		});

	const width = useSharedValue(0);

	const animatedStyles = useAnimatedStyle(() => {
		return {
			width: withTiming(width.value, { duration: 500 }),
		};
	});

	useEffect(() => {
		// Change the width value when your condition is met
		if (eventProduct?.product?._id) {
			width.value = 335; // Set the desired width value
		} else {
			width.value = 220;
		}
	}, [eventProduct]);

	let inCart = false;
	usersCart.map(cartVariant => {
		if (
			cartVariant.type === 'Product' &&
			cartVariant?.productId?._id === eventProduct?.product?._id
		) {
			if (!cartVariant?.productId?.option?.values?.length) {
				inCart = true;
			} else if (
				cartVariant?.optionValue ===
				eventProduct?.product?.option?.values?.[0]?.name
			) {
				inCart = true;
			}
		}
	});

	const availableVariantCombination: any = () => {
		const variantWithQuantity = Object.values(eventProduct?.product?.option?.variatorValues?.[0]?.variatorValues || {})?.find((data: any) => data?.quantity >= 1)
		return variantWithQuantity
	}

	const isItemOutOfStock = availableVariantCombination() ? availableVariantCombination()?.quantity <= 0 : +eventProduct?.product?.option?.inventory?.quantity <= 0;
	const currencyCountry = eventDetails?.affiliatedBrands?.[0]?.address?.countryLabel
	const findCurrencyAndRate = countryAndCurrency?.find((data) => data?.country === currencyCountry)

	// const isItemOutOfStock =
	// 	+eventProduct?.product?.option?.inventory?.quantity <= 0;

	const addToCartText = inCart
		? 'In Cart'
		: isItemOutOfStock
			? t('Out of stock')
			: t('Add to cart');

	const buttonVariant = eventProduct?.product?.option?.values?.length
		? eventProduct?.product?.option?.values?.[0]?.name
			? 'primary'
			: 'disabled'
		: 'primary';

	const buttonVariantWithStockQuantity =
		isItemOutOfStock || inCart ? 'disabled' : buttonVariant;

	const addSelectedProductInTime = async (eventProduct: any) => {
		try {
			await addDoc(
				//@ts-ignore
				collection(
					db,
					'eventSelectedProduct',
					eventDetails?._id,
					'product'
				),
				{
					eventProduct,
					createdAt: Timestamp.fromDate(new Date()),
				}
			);
		} catch (error) {
			console.log({ error }, 'why it failed');
		}
	};

	const onPressProduct = (variant: Variant, product: Product) => {
		if (loggedInUserDetails?.typeOfAccount === 'SELLER') {
			dispatch(setEventProduct({}));
			setTimeout(() => {
				dispatch(
					setEventProduct(transformToPopulated(variant, product))
				);
			}, 500);
			addSelectedProductInTime(transformToPopulated(variant, product));
		}
		setSelectedVariant(transformToPopulated(variant, product));
		// setVariant(product.variants);
		// setProduct(product);
		setModalState(true);
	};

	return (
		<View style={styles.flex1}>
			{modalState && (
				<ModalComponent
					type={isLessThanDesktopBase ? 'fullpage' : 'sidebar'}
					header={`${t('Product View')}`}
					openState={modalState}
					isNotScrollable
					isProductModal
					onClose={() => setModalState(!modalState)}
				>
					<View style={{ marginVertical: 20 }}>
						<ProductView
							variant={selectedVariant}
							setModalState={setModalState}
							productData={product}
							eventDetails={eventDetails}
						/>
					</View>
				</ModalComponent>
			)}
			<Pressable
				onPress={() => {
					onPressProduct(variant, product);
					// dispatch(
					// 	setEventProduct({
					// 		desc: '12 Colors Velvet Matte Lipsticks Pencil Natural Waterproof',
					// 		price: 328.85,
					// 	})
					// );
				}}
			>
				<Animated.View
					style={[
						styles.container,
						isHighlightedProduct && animatedStyles,
					]}
				>
					<Image style={styles.image} source={{ uri }} />
					<View style={styles.info}>
						<Text style={styles.text}>
							{isHighlightedProduct
								? shortenName(productName, 15)
								: productName}
						</Text>
						<Text style={styles.price}>
							{getSymbolFromCurrency(findCurrencyAndRate?.currency || 'eur')}
							{twoDp((availableVariantCombination()?.price || price) * (findCurrencyAndRate?.rate || 1))}
						</Text>
					</View>
					{isHighlightedProduct ? (
						<View style={{ alignSelf: 'center' }}>
							<Button
								title={addToCartText}
								variant={
									loggedInUserDetails?.typeOfAccount ===
										'SELLER'
										? 'disabled'
										: buttonVariantWithStockQuantity
								}
								loading={
									createLoading || isLoadingVisitorAddToCart
								}
								onPress={mutateCart}
							/>
						</View>
					) : (
						<View></View>
					)}
				</Animated.View>
			</Pressable>
		</View>
	);
};

const styles = StyleSheet.create({
	flex1: {
		// flex: 1,
		// marginBottom: 15,
	},
	container: {
		width: 220,
		flexDirection: 'row',
		justifyContent: 'space-between',
		backgroundColor: palettes.light[0],
		borderRadius: 6,
		marginRight: 10,
	},
	image: {
		height: 78,
		width: 78,
		marginRight: '12px',
		borderTopLeftRadius: 6,
		borderBottomLeftRadius: 6,
	},
	info: {
		justifyContent: 'space-around',
		// width: '60%',
		lineHeight: 16,
	},
	text: {
		fontSize: 11,
		lineHeight: 16,
		fontStyle: 'normal',
		fontWeight: 'normal',
	},
	price: {
		fontWeight: 'bold',
		color: '#10162',
		fontSize: 13,
		lineHeight: 18,
	},
});

export default EventProductCard;
